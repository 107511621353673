export function newCampanha() {
    return {
        nome: '',
        budget: '',
        company_id: 0,
        fb_code: '',
        campanha_id: '',
        account_selected: '',
        is_active: false,
        flyer_endereco_pos_x: '190',
        flyer_endereco_pos_y: '2150',
        flyer_endereco_font_size: '45',
        flyer_endereco_font_cor: 'white',
        flyer_whatsapp_pos_x: '190',
        flyer_whatsapp_pos_y: '2222',
        flyer_whatsapp_font_size: '45',
        flyer_whatsapp_font_cor: 'white',
        flyer_instagram_pos_x: '190',
        flyer_instagram_pos_y: '3000',
        flyer_instagram_font_size: '45',
        flyer_instagram_font_cor: 'white',
        flyer_qrcode_pos_x: '1290',
        flyer_qrcode_pos_y: '2060',
        flyer_qrcode_size: '9',
        flyer_qrcode_background_cor: '#F9FAFD',
        flyer_qrcode_fill_cor: '#6400AC',
        flyer_sorteio_data_pos_x: '190',
        flyer_sorteio_data_pos_y: '2088',
        flyer_sorteio_data_font_size: '45',
        flyer_sorteio_data_font_cor: 'white',
        cartaz_endereco_pos_x: '400',
        cartaz_endereco_pos_y: '4420',
        cartaz_endereco_font_size: '60',
        cartaz_endereco_font_cor: 'white',
        cartaz_whatsapp_pos_x: '400',
        cartaz_whatsapp_pos_y: '4555',
        cartaz_whatsapp_font_size: '60',
        cartaz_whatsapp_font_cor: 'white',
        cartaz_instagram_pos_x: '200',
        cartaz_instagram_pos_y: '6000',
        cartaz_instagram_font_size: '60',
        cartaz_instagram_font_cor: 'white',
        cartaz_qrcode_pos_x: '2805',
        cartaz_qrcode_pos_y: '4330',
        cartaz_qrcode_size: '12',
        cartaz_qrcode_background_cor: '#F9FAFD',
        cartaz_qrcode_fill_cor: '#6400AC',
        cartaz_sorteio_data_pos_x: '400',
        cartaz_sorteio_data_pos_y: '4290',
        cartaz_sorteio_data_font_size: '60',
        cartaz_sorteio_data_font_cor: 'white',
    };
}
