import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { campanhaStore, userActionStore } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newCampanha } from '@/interfaces/campanhas';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.tab = 'tab_dados_cadastrais';
        this.entityObject = null;
        this.search = '';
        this.campanhaUploadFiles = [];
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        // Verifique e formate a data antes de enviar
        if (this.entityObject.data_inicio) {
            this.entityObject.data_inicio = new Date(this.entityObject.data_inicio)
                .toISOString()
                .split('T')[0];
        }
        if (this.entityObject.data_termino) {
            this.entityObject.data_termino = new Date(this.entityObject.data_termino)
                .toISOString()
                .split('T')[0];
        }
        if (this.id) {
            const result = await campanhaStore.updateCampanha(this.entityObject);
        }
        else {
            const result = await campanhaStore.createCampanha(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
    }
    async sincronizar() {
        await campanhaStore.sincronizarCampanha(this.id);
    }
    showLog(ref) {
        const form = ref;
        form.show();
    }
    get userActionsAcessoAOLogDoSistema() {
        return userActionStore.acessoAOLogDoSistema;
    }
    async mounted() {
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await campanhaStore.getCampanha(this.id);
        }
        else {
            this.entityObject = newCampanha();
        }
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            DateFieldComponent,
            LogShowComponent,
            SaveButton,
            UploadFilesComponent,
        },
    })
], Create);
export default Create;
